<template>
  <q-page class="q-pa-none row justify-center">
    <q-card style="max-width: 600px; width: 600px" flat>
      <q-card-section style="height: 100%">
        <q-card>
          <q-card-section>
            <q-tab-panels
              v-model="tab"
              class="no-shadow rounded-borders"
              style="height: calc(100% - 72px)"
            >
              <q-tab-panel name="expense">
                <div class="row">
                  <div class="col-12 q-mb-md">
                    <q-input
                      v-model="item.date"
                      filled
                      label="Date"
                      type="date"
                    />
                  </div>
                  <div class="col-12 q-mb-md">
                    <q-select
                      v-model="item.category"
                      filled
                      :options="categories"
                      label="Category"
                    />
                  </div>
                  <div class="col-12 q-mb-md">
                    <q-input
                      v-model="item.amount"
                      filled
                      label="Amount"
                      type="number"
                      prefix="Php"
                    />
                  </div>
                  <div class="col-12 q-mb-md">
                    <q-input v-model="item.notes" filled label="Notes" />
                  </div>
                  <div class="col-12 q-mb-md">
                    <q-btn
                      class="full-width"
                      color="green"
                      size="lg"
                      filled
                      label="Save"
                      @click="SaveExpense"
                    />
                  </div>
                </div>
              </q-tab-panel>

              <q-tab-panel name="income">
                <div class="col-12 q-mb-md">
                  <q-input
                    v-model="income.date"
                    filled
                    label="Date"
                    type="date"
                  />
                </div>
                <div class="col-12 q-mb-md">
                  <q-select
                    v-model="income.category"
                    filled
                    :options="incomeCategories"
                    label="Category"
                  />
                </div>
                <div class="col-12 q-mb-md">
                  <q-input
                    v-model="income.amount"
                    filled
                    label="Amount"
                    type="number"
                    prefix="Php"
                  />
                </div>
                <div class="col-12 q-mb-md">
                  <q-input v-model="income.notes" filled label="Notes" />
                </div>
                <div class="col-12 q-mb-md">
                  <q-btn
                    class="full-width"
                    color="green"
                    size="lg"
                    filled
                    label="Save"
                    @click="SaveIncome"
                  />
                </div>
              </q-tab-panel>
            </q-tab-panels>

            <q-tabs align="justify" v-model="tab" switch-indicator>
              <q-tab name="expense" icon="mail" label="Expense" />
              <q-tab name="income" icon="alarm" label="Income" />
            </q-tabs>
          </q-card-section>
        </q-card>
      </q-card-section>
    </q-card>
  </q-page>
</template>

<script>
import { defineComponent, ref, onMounted, computed } from "vue";
import api from "../api/index";
import { useStore } from "vuex";
import general from "../mixins/general";

export default defineComponent({
  name: "Input",
  setup() {
    const store = useStore();
    const { NotifyUser, getMoment } = general();
    const SheetId = store.getters["SheetId"];
    const key = store.getters["Key"];
    const tab = ref("expense");
    const signedIn = computed(() => store.getters["SignedIn"]);

    const item = ref({
      date: getMoment().format("yyyy-MM-DD"),
      category: null,
      amount: 0,
      notes: null,
    });

    const income = ref({
      date: getMoment().format("yyyy-MM-DD"),
      category: null,
      amount: 0,
      notes: null,
    });

    const columns = ref([
      { label: "Time", align: "left" },
      { label: "Category", align: "left" },
      { label: "Amount", align: "left" },
      { label: "Notes", align: "left" },
    ]);

    const categories = ref(["Test"]);
    const incomeCategories = ref(["Test"]);

    const items = ref([]);

    // const ReadData = () => {
    //   api.ReadRows(SheetId, key).then((r) => {
    //     console.log(r.data.values);
    //     items.value = [...r.data.values];
    //   });
    // };

    const GetExpCategories = () => {
      api.GetExpCategories(SheetId, key).then((r) => {
        categories.value = [...r.data.values[0]];
        if (categories.value.length > 0)
          item.value.category = categories.value[0];
      });
    };

    const GetIncCategories = () => {
      api.GetIncCategories(SheetId, key).then((r) => {
        incomeCategories.value = [...r.data.values[0]];
        if (incomeCategories.value.length > 0)
          income.value.category = incomeCategories.value[0];
      });
    };

    const InvokeAppendAPI = (range, values) => {
      return window.gapi.client.sheets.spreadsheets.values.append({
        spreadsheetId: SheetId,
        range: range,
        valueInputOption: "USER_ENTERED",
        resource: {
          values: [...values],
        },
      });
    };

    const SaveExpense = () => {
      //if (signedIn.value) {
      if (item.value.amount === 0) {
        NotifyUser({
          success: false,
          message: "Enter a non-zero value",
        });
        return;
      }

      var payload = [
        [
          item.value.date,
          item.value.amount,
          item.value.notes,
          item.value.category,
        ],
      ];
      InvokeAppendAPI("Transactions!B4:E4", payload).then((response) => {
        var success = response.status === 200;
        if (success) {
          item.value.amount = 0;
          item.value.notes = null;
          item.value.date = getMoment().format("yyyy-MM-DD");
        }
        NotifyUser({
          success: response.status === 200,
          message: response.statusText || "Success",
        });
      });
      // } else {
      //   NotifyUser({
      //     success: false,
      //     message: "Please authenticate before you can proceed",
      //   });
      // }
    };

    const SaveIncome = () => {
      if (signedIn.value) {
        if (income.value.amount === 0) {
          NotifyUser({
            success: false,
            message: "Enter a non-zero value",
          });
          return;
        }

        var payload = [
          [
            income.value.date,
            income.value.amount,
            income.value.notes,
            income.value.category,
          ],
        ];
        InvokeAppendAPI("Transactions!G4:J4", payload).then((response) => {
          var success = response.status === 200;
          if (success) {
            income.value.amount = 0;
            income.value.notes = null;
            income.value.date = getMoment().format("yyyy-MM-DD");
          }
          NotifyUser({
            success: response.status === 200,
            message: response.statusText || "Success",
          });
        });
      } else {
        NotifyUser({
          success: false,
          message: "Please authenticate before you can proceed",
        });
      }
    };

    // const Test = () => {
    //   window.gapi.client.script.scripts
    //     .run({
    //       scriptId: "1UUdzY-LCRfxzO3IjIQlUTnAg9bsgnlpuBpKw4JXA0bJg8VExD5coDelG",
    //       resource: {
    //         function: "GetExpenseCategories",
    //       },
    //     })
    //     .then((response) => {
    //       console.log(response);
    //     });
    // };

    onMounted(() => {
      store.dispatch("UpdateHeader", "Data Entry");
      GetExpCategories();
      GetIncCategories();
      //Test();
    });

    return {
      SaveIncome,
      SaveExpense,
      income,
      incomeCategories,
      categories,
      tab,
      item,
      items,
      columns,
    };
  },
});
</script>
